.glass-effect {
    background: rgba(60, 44, 44, 0.1);
    /* Semi-transparent white */
    border-radius: 12px;
    /* Rounded corners */
    padding: 20px;
    padding-top: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Subtle shadow */
    backdrop-filter: blur(10px);
    /* Frosted glass effect */
    border: 1px solid rgba(255, 255, 255, 0.2);
    /* Light border */
    transition: all 0.3s ease;
    /* Smooth transition on hover */
}

.glass-effect:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    /* Slightly larger shadow on hover */
    backdrop-filter: blur(12px);
    /* Increase blur on hover */
}

/* Sub-navbar styles */
.sub-navbar {
    height: 30px !important;
    /* Set a maximum height for compact layout */
    font-size: 15px;
    padding-right: 20px;
    /* Make font smaller */
    background-color: #f8f9fa;
    /* Light background color */
    color: #6c757d;
    align-items: center !important
        /* Muted text color */
}