.glass-effect2 {
    background: linear-gradient(135deg, rgba(150, 95, 190, 0.645), rgba(78, 43, 87, 0.175));
    /* Semi-transparent white */
    border-radius: 12px;
    /* Rounded corners */
    padding: 20px;
    padding-top: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Subtle shadow */
    backdrop-filter: blur(10px);
    /* Frosted glass effect */
    border: 1px solid rgba(255, 255, 255, 0.2);
    /* Light border */
    transition: all 0.3s ease;
    /* Smooth transition on hover */
}

.glass-effect2:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    /* Slightly larger shadow on hover */
    backdrop-filter: blur(12px);
    /* Increase blur on hover */
}