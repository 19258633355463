.custom-date-picker {
    height: 38px !important;
    padding: 8px 12px !important;
    border-radius: 4px !important;
    border: 1px solid #ced4da !important;
    box-shadow: none !important;
    font-size: 16px !important;
    color: black !important;
    /* Ensures text is black */
    background-color: white !important;
    /* Ensures background is white */
}